<template>
    <div class="detail-container">
        <div class="detail-right">
            <div class="detai-title">员工职能</div>
            <v-button text="返回" @click="returnClick()"></v-button>
        </div>
        <div class="user-info">
            <div class="username">姓名：{{ userInfo.userName }}</div>
            <div class="phone-number">手机号：{{ userInfo.mobileNum }}</div>
        </div>
        <tabs-panel
            ref="tabs"
            class="tabs-panel"
            :tabs="tabs"
            :keepAlive="false"
            :activeLabel.sync="activeLabel"
        />
    </div>
</template>

<script>
import { _localStorage } from "common/utils";
import { TabsPanel } from "components/bussiness";
import OrderResponsibility from "./infoTemplate/OrderResponsibility";
export default {
    name: "ResponsibilityForm",
    components: {
        TabsPanel,
    },
    data() {
        return {
            activeLabel: "工单职责",
            userId: '',
            orgId: '',
            tabs: [],
            userInfo: {},
        };
    },
    created () {
        let { userId, orgId } = this.$route.query
        this.orgId = orgId
        this.userId = userId
        this.tabs = [
            {
                label: "工单职责",
                component: OrderResponsibility,
                props: {
                    orgId: this.orgId,
                    userId: this.userId
                }
            },
        ]
    },
    mounted() {
        console.log(this.tabs, 'this.userId11')
        this.handleQueryParams();
    },
    methods: {
        returnClick() {
            this.$router.go(-1)
        },
        handleQueryParams() {
            const { mobileNum, userName } = this.$route.query;
            this.userInfo = {
                userName,
                mobileNum
            }
            console.log(this.userInfo)
        },
    },
};
</script>

<style lang="scss" scoped>
.detail-container {
    width: 100%;
    box-sizing: border-box;
    overflow: scroll;
    .detail-right {
        display: flex;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
        .detai-title {
            font-size: 18px;
            font-weight: 600;
            color: #aaa;
        }
    }
    .list-container {
        height: 85%;
    }
    .user-info {
        width: 97.5%;
        display: flex;
        font-size: 16px;
        font-weight: bold;
        padding: 0 20px;
        margin-left: 20px;
        box-sizing: border-box;
        background-color: rgba(251, 251, 251, 1);
        color: #666;
        height: 60px;
        align-items: center;
        border: 1px solid rgba(242, 242, 242, 1);
        border-radius: 4px;
        margin-bottom: 20px;
        & div {
            margin-right: 80px;
        }
    }
}
</style>
