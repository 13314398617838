var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderResponsibility" },
    [
      _c(
        "div",
        { staticClass: "v-tree-wrapper" },
        [
          _c("v-tree", {
            key: 1,
            attrs: {
              data: _vm.userTreeData,
              defaultProps: _vm.treeProps,
              expandAll: true,
              expandOnClickNode: false,
            },
          }),
        ],
        1
      ),
      _c("v-button", { staticClass: "editBtn", on: { click: _vm.edit } }, [
        _vm._v("编辑"),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "工单类型",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", [_vm._v("请选择该员工需承担职责的工单类型")]),
          _c(
            "div",
            { staticClass: "tree_content" },
            [
              _c("v-tree", {
                key: 2,
                ref: "tree",
                attrs: {
                  data: _vm.treeData,
                  defaultProps: _vm.treeProps,
                  expandAll: true,
                  expandOnClickNode: false,
                  checkOnClickNode: true,
                  showCheckbox: true,
                  defaultCheckedKeys: _vm.defaultCheckedKeys,
                },
                on: { check: _vm.onCheck },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }