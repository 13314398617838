<template>
    <div class="orderResponsibility">
        <!-- :defaultProps="treeProps" -->
        <!-- @loadData="treeLoad" -->
        <!--  -->
        <div class="v-tree-wrapper">
            <v-tree
                :key="1"
                :data="userTreeData"
                :defaultProps="treeProps"
                :expandAll="true"
                :expandOnClickNode="false"
            ></v-tree>
        </div>
       <v-button class="editBtn" @click="edit">编辑</v-button>
        <el-dialog
            title="工单类型"
            :visible.sync="dialogVisible"
            width="30%">
            <div>请选择该员工需承担职责的工单类型</div>
            <div class="tree_content">
                <v-tree
                    ref="tree"
                    :key="2"
                    :data="treeData"
                    :defaultProps="treeProps"
                    :expandAll="true"
                    :expandOnClickNode="false"
                    :checkOnClickNode="true"
                    :showCheckbox="true"
                    :defaultCheckedKeys="defaultCheckedKeys"
                    @check="onCheck"
                ></v-tree>
            </div>
            <div class="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="onConfirm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { vTree } from 'components/control'
import { getResponsibilityInfoURL, getuserDutyListURL, addResponsibility } from '../api'
export default {
    name: 'OrderResponsibility', // 工单职责
    components: {
        vTree
    },
    props: {
        userId: String,
    },
    data () {
        return {
            dialogVisible: false,
            treeData: [
                {
                    id: '1',
                    label: '一级 1',
                    children: [{
                        id: '1-1',
                        label: '二级 1-1',
                        children: [{
                            id: '1-1-1',
                            label: '三级 1-1-1'
                        }]
                    }]
                },
                {
                    id: '2',
                    label: '一级 2',
                    children: [{
                        id: '2-1',
                        label: '二级 2-1',
                        children: [{
                            id: '2-1-1',
                            label: '三级 2-1-1'
                        }]
                    }, {
                        id: '2-2',
                        label: '二级 2-2',
                        children: [{
                            id: '2-2-1',
                            label: '三级 2-2-1'
                        }]
                    }]
                }, {
                    id: '3',
                    label: '一级 3',
                    children: [{
                        id: '3-1',
                        label: '二级 3-1',
                        children: [{
                            id: '3-1-1',
                            label: '三级 3-1-1'
                        }]
                    }, {
                        id: '3-2',
                        label: '二级 3-2',
                        children: [{
                            id: '3-2-1',
                            label: '三级 3-2-1'
                        }]
                    }]
                }
            ],
            userTreeData: [],
            treeProps: {
                children: 'childrenList',
                label: 'name'
            },
            defaultCheckedKeys: [],
            CheckedKeys: [],
            isNeverChange: false,// 没有点过任何内容
        }
    },
    created () {
        console.log(this.userId, 'this.userId11')
        this.getResponsibilityInfo()
        this.getuserDutyList()
    },
    methods: {
        getResponsibilityInfo () {
            this.$axios.get(`${getResponsibilityInfoURL}?orgId=${this.$route.query.orgId}`).then((res) => {
                if (res.status === 100) {
                    this.treeData = [...res.data]
                }
            })
        },
        getuserDutyList () {
            this.$axios.get(`${getuserDutyListURL}?userId=${this.userId}&orgId=${this.$route.query.orgId}`).then((res) => {
                if (res.status === 100) {
                    this.userTreeData = [...res.data]
                }
            })
        },
        async treeLoad (node, resolve) {
          console.log(node, resolve)
        },
        selectNode (data) {
            let { name, nodeLevel } = data
            this.$nextTick(() => {
            })
        },
        onCheck (data, checkedObj) {
            this.isNeverChange = false
            this.CheckedKeys = []
            console.log(data, checkedObj, 'data, checkedObj')
            checkedObj.checkedNodes.forEach((item) => {
                this.CheckedKeys.push(item.id)
                // if (item.childrenList.length === 0) {
                //     this.CheckedKeys.push(item.id)
                // }
            })
            this.CheckedKeys = this.CheckedKeys.concat(checkedObj.halfCheckedKeys)
        },

        edit () {
            this.isNeverChange = true
            this.dialogVisible = true
            this.defaultCheckedKeys = []
            this.getChecked(this.userTreeData)
        },
        // 获取当前人id
        getChecked (arr) {
            arr.forEach((ele) => {
                if (ele.childrenList.length === 0) {
                    this.defaultCheckedKeys.push(ele.id)
                } else {
                    this.getChecked(ele.childrenList)
                }
            })
        },
        // 点击确认
        onConfirm () {
            if(this.isNeverChange) {
                // this.dialogVisible = false
                // return;
                this.onCheck('',{
                    checkedNodes: this.$refs.tree.getCheckedNodes(),
                    halfCheckedKeys: this.$refs.tree.getHalfCheckedKeys()
                })

            }
            // console.log(this.defaultCheckedKeys, 'this.defaultCheckedKeys')
            let param = []
            let checkedKeys = []
            if (this.CheckedKeys.length === 0) {
                checkedKeys = [...this.defaultCheckedKeys]
            } else {
                checkedKeys = [...this.CheckedKeys]
            }
            checkedKeys.forEach((item) => {
                param.push({
                    orgId: this.$route.query.orgId,
                    typeId: item,
                    userId: this.userId
                })
            })
            console.log(this.CheckedKeys,'this.CheckedKeys')
            // 只有一级的时候会出问题 所以要把 typeId 删除了 然后传给后端
            if(this.CheckedKeys.length === 0) {
                param.forEach(item => {
                    Reflect.deleteProperty(item, 'typeId')
                })
            }
            this.$axios.post(addResponsibility, param).then((res) => {
                if (res.status === 100) {
                    this.dialogVisible = false
                    this.getuserDutyList()
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.orderResponsibility {
    position: relative;
    padding-top: 50px;
    .editBtn{
        position: absolute;
        right: 50px;
        top: 0;
    }
    .v-tree-wrapper {
        padding-left: 100px;
    }
    .tree_content {
        height: 600px;
        overflow: auto;
    }
    .footer {
        text-align: right;
    }
}

</style>